<template>
  <Details
    title="Party"
    resource="parties"
    :base-path="basePath"
    data-provider="$raaDataProvider"
  >
    <template v-slot:side-panel>
      <DetailsSidePanel>
        <template v-slot:heading>party details</template>
        <template v-slot:text>
          Party is an implicit entity in the RAA, and considered to be a combination of Contact, Community and type of Party.
          Add the basic information about the party.
        </template>
      </DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <PartyForm
        :initial-values="initialValues"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import PartyForm from "@/views/auth/raa-devtools/parties/PartyForm";
  import {v4} from "uuid";

  export default {
    name: "PartyCreate",
    mixins: [ModalNavigation],
    components: {
      PartyForm,
      Details,
      DetailsSidePanel,
    },
    computed: {
      basePath: function () {
        return this.$route.name.replace('create', 'index');
      },
      initialValues() {
        return { id: v4(), type: 'person'}
      }
    }
  }
</script>


